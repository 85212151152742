import axios from 'axios'
import utils from './utils'
import Cookies from 'js-cookie'
import _ from "lodash"
const transformRequest = data => {
    if (utils.isFormData(data) ||
        utils.isArrayBuffer(data) ||
        utils.isBuffer(data) ||
        utils.isStream(data) ||
        utils.isFile(data) ||
        utils.isBlob(data) ||
        utils.isArrayBufferView(data) ||
        utils.isURLSearchParams(data)
    ) {
        return data
    }
    if (utils.isObject(data)) {
        for (var pkey in data) {
            if (data[pkey] === null || typeof (data[pkey]) === 'undefined') {
                delete data[pkey]
            }
        }
        data = utils.params(data);
        return data
    }
    return data
};

axios.defaults.transformRequest.unshift(transformRequest);
axios.defaults.withCredentials = true;

const basePath = () => {
    let basePath = process.env.VUE_APP_SERVER_PATH || location.protocol + '//' + location.host;
    if (basePath.charAt(basePath.length - 1) === '/') {
        basePath = basePath.substring(0, basePath.length - 1)
    }
    return basePath
};

const checkUrl = (url) => {
    if (url) {
        if (url.indexOf('/') === 0) {
            url = url.substring(1)
        }
        if (url.indexOf('http') !== 0) {
            url = basePath() + '/' + url
        }
    } else {
        url = basePath()
    }
    return url
};

const instance = axios.create({
    baseURL: basePath()
});

instance.basePath = function () {
    return basePath()
};

instance.url = function(url) {
    return checkUrl(url)
};

instance.login = function() {
    Cookies.remove('access_token');
    let redirect = !process.env.VUE_APP_SERVER_LOCAL ? '?redirect=/' : '?redirect=' + process.env.VUE_APP_SERVER_LOCAL;
    window.location.href = basePath() + '/login.html' + redirect;
};

instance.download = function (url, options) {
    if (!url) {
        return
    }
    if (url.indexOf('http') !== 0) {
        url = checkUrl(url)
    }
    if (_.isEmpty(options)) {
        options = {}
    }
    if (!_.isEmpty(options)) {
        let data = utils.params(options)
        url += '?' + data
    }
    window.open(url)
};

instance.interceptors.request.use(config => {
    // 可以在这里加入ajax请求前处理的业务，如加入access_token
    if (config.url.indexOf('http') !== 0) {
        config.url = checkUrl(config.url)
    }
    if (config.url.indexOf('/oauth/token') < 0 && config.url.indexOf('/session/load') < 0 && Cookies.get('access_token')) {
        config.headers['Authorization'] = 'Bearer ' + Cookies.get('access_token')
    }
    return config
});

instance.interceptors.response.use(response => {
    return response.data
}, error => {
    let response = error.response;
    if (response.status === 401) {
        instance.login();
        return false;
    }
    return Promise.reject(response.data)
});

export default instance
