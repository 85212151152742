// 用户认证
import Service from './base'
export default {
    // 获取用户TOKEN
    token () {
        return Service.get('/platformauth/open/session/load')
    },
    // 取当前人员信息
    current () {
        return Service.get('/platformcore/platformapi/core/person/current')
    },
    // 取用户有权限访问的应用
    userApplicationList (body) {
        return Service.post('/platformcore/platformapi/core/application/current', body)
    }
}
