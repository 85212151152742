import _ from 'lodash'
import iaxios from './iaxios'

const defaultPagination = {
    current: 1,
    size: 10
};

const paginationBody = (body, pagination) => {
    let _body = _.cloneDeep(body || {});
    pagination = _.defaultsDeep(pagination, defaultPagination);
    let _pag = {
        sort: pagination.sort || null,
        order: pagination.order || null,
        pageOffset: pagination.pageOffset || pagination.current || 1,
        pageSize: pagination.pageSize || pagination.size || 10
    };
    return _.defaultsDeep(_body, _pag)
};

export default {
    defaultPagination,
    paginationBody,
    get (url, options) {
        return iaxios.get(url, options)
    },
    post (url, body, options) {
        return iaxios.post(url, body, options)
    },
    basePath () {
        return iaxios.basePath()
    },
    login () {
        return iaxios.login()
    },
    download: function (url, options) {
        return iaxios.download(url, options)
    }
}
