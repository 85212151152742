<template>
  <el-container>
    <el-header class="clear">
      <!-- logo -->
      <div class="logo">
        <img src="@/assets/images/icon.jpg" />
      </div>
      <div class="header-block">
        <slot name="first"></slot>
      </div>
      <div class="header-block">
        <slot name="second"></slot>
      </div>
      <!-- 右侧菜单 -->
      <div class="tool-body">
        <div class="user-info-cell">
          <el-badge
            :value="badgeValue"
            :max="99"
            class="user-info-badge"
            v-if="badgeValue"
          ></el-badge>
          <slot name="user-info"></slot>
        </div>
        <div class="header-block clearfix">
          <slot name="tips-box"></slot>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside class="left-tool-aside" style="width: 61px">
        <ul>
          <li
            class="tool-item"
            v-for="(item, index) in asideTools"
            :key="index"
          >
            <a
              href="javascript:void(0);"
              @click="clickAsideTool(item)"
              :class="{ active: item.active }"
            >
              <i class="iconfont" :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </a>
          </li>
        </ul>
      </el-aside>
      <el-container>
        <slot></slot>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
export default {
  name: "LeftToolLayout",
  props: {
    badgeValue: [String, Number],
    asideTools: Array,
  },
  data() {
    return {};
  },
  methods: {
    clickAsideTool(item) {
      this.$emit("select-menu", item);
    },
  },
};
</script>
<style scoped>
.logo {
  height: 40px;
  width: auto;
  font-size: 12px;
  padding-right: 20px;
  border-color: rgba(238, 238, 238, 0.3);
  border-right-width: 0px;
  border-right-style: solid;
  color: #fff;
  float: left;
  box-sizing: border-box;
  cursor: pointer;
}
.logo img {
  width: 30px;
  margin: 7px auto;
}
.header-block {
  width: auto;
  float: left;
  padding-right: 10px;
  max-height: 40px;
  overflow-y: hidden;
}
.tool-body {
  height: 40px;
  width: auto;
  float: right;
  box-sizing: border-box;
  text-align: right;
  padding: 0;
}
.user-info-cell {
  position: relative;
  height: 40px;
  width: auto;
  font-size: 12px;
  border-color: rgba(238, 238, 238, 0.3);
  border-style: solid;
  border-width: 0px;
  border-left-width: 1px;
  color: #fff;
  float: right;
  box-sizing: border-box;
}
.user-info-badge {
  position: absolute;
  top: -10px;
  right: -5px;
}
.left-tool-aside {
  color: #333;
  background-color: rgb(238, 241, 246);
  border-right: 1px solid #dfdfdf;
  box-sizing: border-box;
}
.tool-item {
  display: block;
  padding: 5px;
  text-align: center;
}
.tool-item a {
  display: block;
  width: 40px;
  padding: 5px;
  color: #909399;
  font-size: 12px;
}
.tool-item a:hover {
  background: rgb(228, 231, 236);
}
.tool-item a i,
.tool-item a span {
  display: block;
}
.tool-item a i {
  font-size: 24px;
}
.tool-item a.active i,
.tool-item a.active span {
  color: #72c1d8;
}
</style>