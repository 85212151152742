import BaseLayout from './package/base-layout'
import LeftToolLayout from './package/left-tool-layout'

const components = [
    BaseLayout,
    LeftToolLayout
]

const install = function(Vue) {
    components.map(component => {
        Vue.component(component.name, component)
    })
}

if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export default {
    version: '1.0.1',
    name: 'Layouts',
    install,
    BaseLayout,
    LeftToolLayout
}