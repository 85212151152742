<template>
  <div class="clear tool-bar-wrap">
    <div class="clear tool-bar-tools">
      <div class="tool-item" v-for="(item, index) in tools" :key="index">
        <a href="javascript:void(0);" @click="clickTool(item)">
          <i class="iconfont" :class="item.icon"></i>
          <span>{{ item.title }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseToolBar",
  props: {
    tools: Array,
  },
  data() {
    return {}
  },
  methods: {
    clickTool(item) {
      this.$emit("click-tool", item);
    }
  }
};
</script>
<style scoped>
.tool-bar-wrap {
  width: 100%;
  height: 41px;
  line-height: 40px;
}
.tool-item {
  float: left;
  color: #333;
}
.tool-item a {
	color: #333;
	line-height: 16px;
  border-right: 1px solid #dfdfdf;
	padding: 0 6px;
}
.tool-item a span {
	margin-left: 6px;
}
.tool-item:last-child a {
  border-right-width: 0;
}
.tool-item a:hover {
	color: #72c1d8;
}
</style>