import Vue from 'vue'
import App from './App.vue'
import Layouts from '@/layout/index.js'
import Components from '@/components/index.js'
import router from './router'
import store from './store'
import '@/plugins/element.js'
import '@/assets/css/style.css'
import '@/assets/iconfont/iconfont.css'

Vue.config.productionTip = false
Vue.use(Layouts)
Vue.use(Components)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')