import Vue from 'vue'
import Router from 'vue-router'
import routers from './routers'
import store from '@/store'
import Service from '@/request/base'

Vue.use(Router);

const router = new Router({
    routes: routers
});

router.beforeEach((to, from, next) => {
    if (!store.getters.token) {
        store.dispatch('currentToken').finally(() => {
            next()
        }).catch(() => {
            Service.login()
        })
    } else {
        next()
    }
});

export default router