const routers = [{
    path: '/',
    component: () => import('@/views/index'),
    /* redirect: '/today',
    children: [{
        path: '/today',
        component: resolve => require(['@/views/today'], resolve),
        name: 'Today',
        title: '今日',
        icon: 'icon-huanzheguanli'
    }, {
        path: '/record',
        component: resolve => require(['@/views/record'], resolve),
        name: 'Record',
        title: '历史',
        icon: 'icon-chazhao'
    }, {
        path: '/count',
        component: resolve => require(['@/views/count'], resolve),
        name: 'Count',
        title: '统计',
        icon: 'icon-tongji'
    }] */
}];

export default routers