import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import AccountService from '@/request/account'

Vue.use(Vuex);

const state = {
    token: null
};

const mutations = {
    updateToken (state, token) {
        state.token = token
    }
};

const actions = {
    currentToken({commit}) {
        return new Promise((resolve, reject) => {
            let token = Cookies.get('access_token');
            if (token === 'null') {
                token = null;
            }
            if (token) {
                commit('updateToken', token);
                resolve(true);
                return
            }
            AccountService.token().then(res => {
                token = res['result'];
                if (!token) {
                    reject(new Error('获取token失败'))
                }
                Cookies.set('access_token', token);
                commit('updateToken', token);
                resolve(true)
            }).catch(() => {
                reject(new Error('获取token失败'))
            })
        })
    }
};

const getters = {
    token: state => {
        return state.token
    },
    person: state => {
        return state.person
    },
    department: state => {
        return state.department
    }
};

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})
